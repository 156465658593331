import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  InfoListContainerStyled,
  InfoListStyled,
  InfoListItemStyled,
} from "./styles"
import InfoBox from "./../InfoBox"
import TopIcon from "./../../assets/svg/Top"

const InfoList = () => {
  const { introductionImage, methodologyImage } = useStaticQuery(
    graphql`
      query {
        introductionImage: file(
          relativePath: { eq: "infobox-introduction-image.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        methodologyImage: file(
          relativePath: { eq: "infobox-methodology-image.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <InfoListContainerStyled>
      <InfoListStyled>
        <InfoListItemStyled>
          <InfoBox
            heading="INTRODUCTION"
            description="Sheila Morrison, Brand & Culture Director, introduces the first brand index to focus purely on B2B brands"
            linkText="Read more"
            linkUrl="/introduction/"
            image={introductionImage.childImageSharp.fluid}
            altText="Introduction image"
          />
        </InfoListItemStyled>
        <InfoListItemStyled>
          <InfoBox
            heading="THE TOP 50"
            description="Explore the B2B brands that make up the Top 50"
            linkText="See the Top 50"
            linkUrl="/top-50/"
            icon={<TopIcon />}
          />
        </InfoListItemStyled>
        <InfoListItemStyled>
          <InfoBox
            heading="OUR METHODOLOGY"
            description="Julie Sim, Brand Strategist, explains our methodology"
            linkText="Read more"
            linkUrl="/methodology/"
            image={methodologyImage.childImageSharp.fluid}
            altText="Methodology image"
          />
        </InfoListItemStyled>
      </InfoListStyled>
    </InfoListContainerStyled>
  )
}

export default InfoList
