import styled from "styled-components"
import { media } from "./../../common/MediaQueries"

const HtmlVideoContainerStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    outline: 0;
    transform: scale(2);
    @media ${media.phone} {
      transform: scale(2);
    }
    @media ${media.tablet} {
      transform: scale(1);
    }
  }
  video::-webkit-media-controls-start-playback-button {
    display: none;
  }
  video::-webkit-media-controls {
    display: none;
  }
  video::-webkit-media-controls-panel {
    display: none;
  }
  video::-webkit-media-controls-play-button {
    display: none;
  }
`

const HtmlVideoSoundStyled = styled.div`
  position: absolute;
  //bottom: 40px;
  //transform: translateX(-50%);
  // right: 50%;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  top: 0;
  padding: 16px;
  @media ${media.phone} {
    padding: 32px;
  }
`

export { HtmlVideoContainerStyled, HtmlVideoSoundStyled }
