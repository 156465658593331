import React from "react"
import { SvgComponent } from "./../../definitions/Svg"

const DownloadIcon: SvgComponent = props => (
  <svg
    width="19px"
    height="15px"
    viewBox="0 0 19 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-356.000000, -403.000000)"
        stroke="currentColor"
        strokeWidth="2"
      >
        <g id="Group-Copy-3" transform="translate(357.000000, 404.000000)">
          <polygon
            id="Path"
            strokeLinejoin="round"
            transform="translate(8.500000, 3.500000) rotate(90.000000) translate(-8.500000, -3.500000) "
            points="5 -1.5 12 3.5 5 8.5"
          ></polygon>
          <line
            x1="1.42143242e-14"
            y1="12.5"
            x2="17"
            y2="12.5"
            id="Line-2"
            strokeLinecap="round"
          ></line>
        </g>
      </g>
    </g>
  </svg>
)

export default DownloadIcon
