import React from "react"
import { SvgComponent } from "./../../definitions/Svg"

const ArrowIcon: SvgComponent = props => (
  <svg
    width="16px"
    height="14px"
    viewBox="0 0 16 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        transform="translate(-653.000000, -804.000000)"
        stroke="currentColor"
        strokeWidth="2"
      >
        <g id="Group-11-Copy-3" transform="translate(554.000000, 805.000000)">
          <g transform="translate(107.000000, 6.000000) rotate(-90.000000) translate(-107.000000, -6.000000) translate(101.000000, -0.500000)">
            <line x1="6" y1="0" x2="6" y2="12"></line>
            <polyline
              transform="translate(6.000000, 7.000000) rotate(-225.000000) translate(-6.000000, -7.000000) "
              points="2 3 10 3 10 11"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowIcon
