import React, { useState, useRef, useEffect } from "react"
import ReactPlayer from "react-player"
import { ReactPlayerProps } from "react-player"
import { HtmlVideoContainerStyled, HtmlVideoSoundStyled } from "./styles"
import SoundController from "./../SoundController"

interface IProps extends Pick<ReactPlayerProps, "url"> {
  videoSrc: string
  posterSrc?: string
  reference?: any
  onEndedEvent?: () => void
}

const HtmlVideo: React.FC<IProps> = ({
  videoSrc,
  posterSrc,
  reference,
  onEndedEvent,
}) => {
  const [muted, setMuted] = useState(true)
  const videoParentRef = useRef(null)

  const soundToggleHandler = () => {
    setMuted(!muted)
    if (muted) {
      videoParentRef.current.children[0].children[0].muted = false
    } else {
      videoParentRef.current.children[0].children[0].muted = true
    }
  }

  return (
    <HtmlVideoContainerStyled ref={videoParentRef}>
      {/* <video
        autoplay="autoplay"
        plays-inline
        playsInline
        ref={reference}
        muted
        onEnded={onEndedEvent}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      <HtmlVideoSoundStyled>
        <SoundController clickHandler={soundToggleHandler} isPlaying={muted} />
      </HtmlVideoSoundStyled> */}

      <ReactPlayer
        playing
        muted={true}
        playsinline={true}
        url={videoSrc}
        width="100%"
        height="100%"
        onEnded={onEndedEvent}
        ref={reference}
      />
      <HtmlVideoSoundStyled>
        <SoundController clickHandler={soundToggleHandler} isPlaying={muted} />
      </HtmlVideoSoundStyled>
    </HtmlVideoContainerStyled>
  )
}

export default HtmlVideo
