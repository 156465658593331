import React from "react"

import {
  IconButtonStyled,
  IconButtonTypoStyled,
  IconButtonMediaStyled,
} from "./styles"
import DownloadIcon from "./../../assets/svg/Download"
import ArrowIcon from "./../../assets/svg/Arrow"

interface IProps {
  as?: string
  text?: string
  href?: string
  target?: string
  isToDownload?: boolean
  isInternalLink?: boolean
  isRotatedIcon?: boolean
  state?: any
}

const IconButton: React.FC<IProps> = ({
  as,
  text,
  isToDownload,
  isInternalLink,
  isRotatedIcon,
  href,
  target,
  state,
}) => (
  <IconButtonStyled
    to={href}
    {...{ target, as, isInternalLink, isToDownload, isRotatedIcon, state }}
  >
    <IconButtonTypoStyled>{text}</IconButtonTypoStyled>
    {isToDownload && (
      <IconButtonMediaStyled>
        <DownloadIcon />
      </IconButtonMediaStyled>
    )}
    {isInternalLink && (
      <IconButtonMediaStyled>
        <ArrowIcon />
      </IconButtonMediaStyled>
    )}
  </IconButtonStyled>
)

export default IconButton
