import React from "react"

import {
  HeroWelcomeStyled,
  HeroWelcomeMediaStyled,
  HeroWelcomeHeadingStyled,
  HeroWelcomeDescStyled,
  HeroWelcomeActionStyled,
} from "./styles"
import Image from "./../Image"
import { Heading4, Typo3 } from "./../Typography"
import IconButton from "./../IconButton"

interface IProps {
  heading: string
  description: string
  linkText: string
  linkUrl: string
  image: React.ReactNode
}

const HeroWelcome: React.FC<IProps> = ({
  heading,
  description,
  linkText,
  linkUrl,
  image,
}) => {
  return (
    <HeroWelcomeStyled>
      <HeroWelcomeMediaStyled>
        <Image fluid={image} alt="BB intro image" />
      </HeroWelcomeMediaStyled>
      <HeroWelcomeHeadingStyled>
        <Heading4 as="h1" fontWeight="900">
          {heading}
        </Heading4>
      </HeroWelcomeHeadingStyled>
      <HeroWelcomeDescStyled>
        <Typo3>{description}</Typo3>
      </HeroWelcomeDescStyled>
      <HeroWelcomeActionStyled>
        <IconButton href={linkUrl} text={linkText} isToDownload />
      </HeroWelcomeActionStyled>
    </HeroWelcomeStyled>
  )
}
export default HeroWelcome
