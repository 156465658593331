import styled from "styled-components"
import { media } from "./../../common/MediaQueries"

const HeroWelcomeStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const HeroWelcomeMediaStyled = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: 12px;
  max-width: 188px;
  @media ${media.phone} {
    margin-bottom: 80px;
    max-width: 470px;
  }
  @media ${media.tablet} {
    margin-bottom: 88px;
  }
`

const HeroWelcomeHeadingStyled = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 540px;
  margin-bottom: 6px;
  @media ${media.phone} {
    margin-bottom: 12px;
  }
`

const HeroWelcomeDescStyled = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin-bottom: 10px;
  @media ${media.phone} {
    margin-bottom: 40px;
  }
`

const HeroWelcomeActionStyled = styled.div`
  display: flex;
  justify-content: center;
`

export {
  HeroWelcomeStyled,
  HeroWelcomeMediaStyled,
  HeroWelcomeHeadingStyled,
  HeroWelcomeDescStyled,
  HeroWelcomeActionStyled,
}
