import styled from "styled-components"

import { media } from "./../../common/MediaQueries"
import {
  InfoBoxStyled,
  InfoBoxHeadingStyled,
  InfoBoxOverlayStyled,
  InfoBoxIconStyled,
} from "./../InfoBox/styles"

const InfoListContainerStyled = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
`

const InfoListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${media.tablet} {
    flex-direction: row;
  }
`

const InfoListItemStyled = styled.li`
  flex: 1;
  height: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }

  @media ${media.phone} {
    border-bottom: none;
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.white};
    }
  }

  @media ${media.tablet} {
    &:not(:last-child) {
      border-bottom: none;
    }
  }

  &:nth-child(2) {
    img {
      display: none;
    }
    ${InfoBoxIconStyled} {
      color: ${({ theme }) => theme.colors.brand};
    }
    ${InfoBoxStyled} {
      &:hover {
        ${InfoBoxOverlayStyled} {
          background-color: ${({ theme }) => theme.colors.blue};
        }
        ${InfoBoxIconStyled} {
          color: ${({ theme }) => theme.colors.black};
          opacity: 0.4;
        }
      }
    }
    ${InfoBoxOverlayStyled} {
      opacity: 1;
    }
    ${InfoBoxHeadingStyled} {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export { InfoListContainerStyled, InfoListStyled, InfoListItemStyled }
