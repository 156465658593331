import styled from "styled-components"

import { media } from "./../../common/MediaQueries"

const SoundControllerStyled = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  @media ${media.tablet} {
    &:hover {
      opacity: 0.75;
    }
  }
`

const SoundControllerIconStyled = styled.div`
  width: 32px;
  height: 32px;
  @media ${media.phone} {
    width: 40px;
    height: 40px;
  }
`

const SoundControllerTextStyled = styled.p`
  margin-left: 8px;
  font-size: 12px;
  user-select: none;
  @media ${media.phone} {
    font-size: 14px;
  }
`

export {
  SoundControllerStyled,
  SoundControllerIconStyled,
  SoundControllerTextStyled,
}
