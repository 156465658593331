import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { transition } from "./../../common/mixins"

const IconButtonTypoStyled = styled.span`
  margin-right: 10px;
  color: currentColor;
  font-size: 14px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const IconButtonMediaStyled = styled.div`
  position: relative;
  display: flex;
  color: currentColor;
`

const IconButtonStyled = styled(Link)`
  ${transition()};
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  ${({ isToDownload }) =>
    isToDownload &&
    css`
      ${IconButtonTypoStyled} {
        font-size: 12px;
      }
    `}

  ${({ isRotatedIcon }) =>
    isRotatedIcon &&
    css`
      ${IconButtonTypoStyled} {
        margin-right: 0;
        order: 2;
      }
      ${IconButtonMediaStyled} {
        order: 1;
        margin-right: 10px;
        svg {
          transform: rotate(180deg);
        }
      }
    `}
`

export { IconButtonStyled, IconButtonTypoStyled, IconButtonMediaStyled }
