import React from "react"

import {
  SoundControllerStyled,
  SoundControllerIconStyled,
  SoundControllerTextStyled,
} from "./styles"
import SoundOnIcon from "./../../assets/images/svg/sound-on-icon.svg"
import SoundOffIcon from "./../../assets/images/svg/sound-off-icon.svg"

interface IProps {
  isPlaying?: boolean
  clickHandler: void
}

const SoundController: React.FC<IProps> = ({ isPlaying, clickHandler }) => {
  return (
    <SoundControllerStyled onClick={clickHandler}>
      <SoundControllerIconStyled>
        {!isPlaying ? <SoundOnIcon /> : <SoundOffIcon />}
      </SoundControllerIconStyled>
      <SoundControllerTextStyled>
        {!isPlaying ? "Sound off" : "Sound on"}
      </SoundControllerTextStyled>
    </SoundControllerStyled>
  )
}

export default SoundController
