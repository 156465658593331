import React, { useState, useEffect, useRef } from "react"

import {
  HeroIntroStyled,
  HeroIntroMediaStyled,
  HeroIntroContentStyled,
  HeroIntroMediaContainerStyled,
  HeroIntroMediaCtaStyled,
  HeroIntroMediaVideoStyled,
} from "./styles"
import HtmlVideo from "../HtmlVideo"
import HeroWelcome from "./../HeroWelcome"
import { Typo8 } from "./../Typography"
import theme from "../../themes/theme"

interface IProps {
  heading: string
  description: string
  linkText: string
  linkUrl: string
  image: React.ReactNode
  videoSrc: string
  posterSrc?: string
  ctaText: string
}

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0
}

const HeroIntro: React.FC<IProps> = ({
  heading,
  description,
  linkText,
  linkUrl,
  image,
  videoSrc,
  posterSrc,
  ctaText,
}) => {
  const [videoFadeIn, setvideoFadeIn] = useState(false)
  const [videoHidden, setVideoHidden] = useState(false)

  const videoRef = useRef(null)
  const videoMediaContainer = useRef(null)
  const hideVideoHandler = () => {
    setVideoHidden(true)
    //videoRef.current.pause()

    //videoRef.current.player.isPlaying = false
    videoMediaContainer.current.querySelector("video").pause()
  }

  useEffect(() => {
    // if (isSafari() && videoRef.current) {
    //   console.log("is safari")
    // }

    setTimeout(() => {
      setvideoFadeIn(true)
    }, 1000)
  }, [])

  const hideVideo = () => {
    setVideoHidden(true)
  }

  return (
    <HeroIntroStyled isVisible={videoFadeIn}>
      <HeroIntroMediaStyled isHidden={videoHidden}>
        <HeroIntroMediaContainerStyled>
          <HeroIntroMediaVideoStyled ref={videoMediaContainer}>
            <HtmlVideo
              {...{ videoSrc, posterSrc }}
              reference={videoRef}
              onEndedEvent={hideVideo}
            />
          </HeroIntroMediaVideoStyled>
          <HeroIntroMediaCtaStyled onClick={hideVideoHandler}>
            <Typo8>{ctaText}</Typo8>
          </HeroIntroMediaCtaStyled>
        </HeroIntroMediaContainerStyled>
      </HeroIntroMediaStyled>
      <HeroIntroContentStyled isVisible={videoHidden}>
        <HeroWelcome {...{ heading, description, linkText, linkUrl, image }} />
      </HeroIntroContentStyled>
    </HeroIntroStyled>
  )
}

export default HeroIntro
