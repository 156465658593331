import styled from "styled-components"
import { Link } from "gatsby"

import { media } from "./../../common/MediaQueries"
import { transition } from "./../../common/mixins"

import { IconButtonStyled } from "./../IconButton/styles"

const InfoBoxMaskStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
`

const InfoBoxMaskMediaStyled = styled.figure`
  ${transition()};
  width: 100%;
  height: 100%;
`

const InfoBoxContentStyled = styled.div`
  position: relative;
  padding: 20px 16px;
  max-width: 270px;
  z-index: 2;
  @media ${media.phone} {
    padding: 20px;
  }
`

const InfoBoxHeadingStyled = styled.div`
  margin-bottom: 6px;
`

const InfoBoxDescStyled = styled.div`
  margin-bottom: 4px;
`

const InfoBoxActionStyled = styled.div``

const InfoBoxOverlayStyled = styled.div`
  ${transition()};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const InfoBoxStyled = styled(Link)`
  display: block;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  min-height: 240px;

  height: 100%;

  @media ${media.phone} {
    min-height: 344px;
  }

  &:hover {
    ${IconButtonStyled} {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

const InfoBoxIconStyled = styled.div`
  position: absolute;
  right: 29px;
  bottom: 20px;
  width: 180px;
  z-index: 1;

  @media ${media.phone} {
    width: 260px;
    height: 160px;
    right: 20px;
  }
`

export {
  InfoBoxStyled,
  InfoBoxMaskStyled,
  InfoBoxMaskMediaStyled,
  InfoBoxContentStyled,
  InfoBoxHeadingStyled,
  InfoBoxDescStyled,
  InfoBoxActionStyled,
  InfoBoxOverlayStyled,
  InfoBoxIconStyled,
}
