import styled, { css } from "styled-components"

import { media } from "./../../common/MediaQueries"
import { transition } from "./../../common/mixins"

const HeroIntroStyled = styled.div`
  ${transition(0, 0.75)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `}

  @media ${media.tablet} {
    min-height: 678px;
    height: auto;
  }
`

const HeroIntroMediaStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`

const HeroIntroMediaContainerStyled = styled.div`
  position: relative;
  width: 100%;
`

const HeroIntroMediaVideoStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const HeroIntroMediaCtaStyled = styled.div`
  ${transition(0, 0.45)};
  position: absolute;
  left: auto;
  right: 16px;
  bottom: 20px;
  > * {
    user-select: none;
    font-weight: 300;
  }
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }
  @media ${media.phone} {
    bottom: 30px;
    right: 32px;
    > * {
      font-weight: 400;
    }
  }
  @media ${media.tablet} {
    bottom: 40px;
  }
`

const HeroIntroContentStyled = styled.div`
  ${transition(0.5, 2)};
  max-width: 540px;
  width: 100%;
  padding: 20px 20px 30px;
  opacity: 0;
  pointer-events: none;
  @media ${media.phone} {
    padding: 140px 20px 60px;
  }
  @media ${media.tablet} {
    padding: 10px 20px;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`

export {
  HeroIntroStyled,
  HeroIntroMediaStyled,
  HeroIntroContentStyled,
  HeroIntroMediaContainerStyled,
  HeroIntroMediaCtaStyled,
  HeroIntroMediaVideoStyled,
}
