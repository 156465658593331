import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import HeroIntro from "../components/HeroIntro"
import InfoList from "../components/InfoList"
import BBVideo from "../assets/video/bb-video-v3.mp4"
import { media } from "../common/MediaQueries"
import PosterVideoImg from "../assets/images/hero-intro-img-desktop.png"

const HomepageGridStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`

const HomepageGridHeroStyled = styled.div`
  @media ${media.tablet} {
    display: flex;
    height: 100%;
    min-height: calc(100vh - 344px);
  }
`

const HomepageGridContentStyled = styled.div`
  @media ${media.tablet} {
    height: 344px;
  }
`

interface IProps {
  imagesArray?: []
}

const IndexPage: React.FC<IProps> = ({ data }) => {
  const heroIntroImageDesktop = data.heroIntroImgDesktop.childImageSharp.fluid
  const heroIntroImageMobile = data.heroIntroImgMobile.childImageSharp.fluid
  return (
    <Layout withoutFooter isDarkBg>
      <SEO
        title="Luminous B2B’s Brightest Brands"
        description="The only brand index that is solely focused on business-to-business brands. From commercial real estate to financial services to technology – we deep dive into 200 companies across 18 sectors, accessing them across 10 key criteria."
      />
      <HomepageGridStyled>
        <HomepageGridHeroStyled>
          <HeroIntro
            heading="Welcome to B2B’s Brightest Brands"
            description="The only brand index that is solely focused on business-to-business
          brands."
            linkText="Download the report"
            linkUrl="/download-report/"
            image={[
              heroIntroImageMobile,
              {
                ...heroIntroImageDesktop,
                media: `(min-width: 768px)`,
              },
            ]}
            videoSrc={BBVideo}
            posterSrc={PosterVideoImg}
            ctaText="Skip intro"
          />
        </HomepageGridHeroStyled>
        <HomepageGridContentStyled>
          <InfoList />
        </HomepageGridContentStyled>
      </HomepageGridStyled>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    heroNumbersImg: allImageSharp(
      filter: { original: { src: { regex: "/hero-number/" } } }
      sort: { fields: original___src }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 355, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    heroIntroImgDesktop: file(
      relativePath: { eq: "hero-intro-img-desktop.png" }
    ) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroIntroImgMobile: file(
      relativePath: { eq: "hero-intro-img-mobile.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
