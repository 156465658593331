import React from "react"

import {
  InfoBoxStyled,
  InfoBoxMaskStyled,
  InfoBoxMaskMediaStyled,
  InfoBoxContentStyled,
  InfoBoxHeadingStyled,
  InfoBoxDescStyled,
  InfoBoxActionStyled,
  InfoBoxOverlayStyled,
  InfoBoxIconStyled,
} from "./styles"
import IconButton from "./../IconButton"
import { Typo3, Typo2 } from "./../Typography"
import Image from "./../Image"
import theme from "../../themes/theme"

interface IProps {
  section?: string
  heading: string
  description: string
  linkText: string
  linkUrl: string
  image?: object
  icon?: React.ReactNode
  altText?: string
}

const InfoBox: React.FC<IProps> = ({
  heading,
  description,
  linkText,
  linkUrl,
  image,
  icon,
  section,
  altText,
}) => (
  <InfoBoxStyled {...{ section }} to={linkUrl}>
    <InfoBoxMaskStyled>
      <InfoBoxMaskMediaStyled>
        <InfoBoxOverlayStyled />
        {image && <Image fluid={image} alt={altText} />}
        {icon && <InfoBoxIconStyled>{icon}</InfoBoxIconStyled>}
      </InfoBoxMaskMediaStyled>
    </InfoBoxMaskStyled>
    <InfoBoxContentStyled>
      <InfoBoxHeadingStyled>
        <Typo2 fontWeight={theme.fontWeight.bold}>{heading}</Typo2>
      </InfoBoxHeadingStyled>
      <InfoBoxDescStyled>
        <Typo3
          as="div"
          fontWeight={theme.fontWeight.light}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </InfoBoxDescStyled>
      <InfoBoxActionStyled>
        <IconButton as="div" text={linkText} isInternalLink />
      </InfoBoxActionStyled>
    </InfoBoxContentStyled>
  </InfoBoxStyled>
)

export default InfoBox
